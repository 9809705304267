import React from "react";
import BeckonCapitalLayout from "../../layouts/BeckonCapital";
import FormaticSDK from "../../components/Formatic";
import auth from '../../Auth0FE';
import { config } from '../../config';

const formProps = {
  apiServerUrl: config.TRANSFORMD_API_URL,
  serverUrl: config.TRANSFORMD_API_URL_GRAPHQL,
  subscriptionServerUrl: config.TRANSFORMD_SUBSCRIPTION_URL,
};

const leanInvestmentCanvas = () => {
  return (
  <BeckonCapitalLayout title="Lean investment canvas">
    <div className="page__business-form">
          <FormaticSDK
            {...formProps}
            formId={config.TRANSFORMD_FORM_ID}
            apiKey={config.TRANSFORMD_PUBLIC_API_KEY}
            environment={config.TRANSFORMD_ENVIRONMENT}
            channel={"leanInvestmentCanvas"}
            config="default"
            theme="transformd"
            submissionId={auth.getTransformdSubmissionId()}
            initialValues={{"5ff3b61de00ec80104366c58":"leanInvestmentCanvas", "userId":auth.getAuth0UserId(), "userName":auth.getUserName() }}
            runReduxDevTools
          >
          </FormaticSDK>
    </div>
  </BeckonCapitalLayout>
)};

export default leanInvestmentCanvas;
