import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, MenuItem, MenuList, makeStyles, createStyles, Theme, ListItemIcon, ListItemText } from '@material-ui/core';
import { Money, Person, Group, Archive, Event, ChatBubble, Dashboard, TrendingUp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function NavMenu() {
  // const history = useHistory();
  const classes = useStyles();

  // const anchorRef = React.useRef<HTMLButtonElement>(null);

  // const handleClick = target => {
  //   // now let's redirect
  //   console.warn('2nd click', target);
  //   // history.push(target);
  // };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <h2 className='border-bottom'>Collaboration hub</h2>
      </Grid>
      <Grid item xs={12}>
        <MenuList>
          <MenuItem component={Link} to="/apply-for-funding">
            <ListItemIcon>
              <Money />
            </ListItemIcon>
            <ListItemText>Apply for funding</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText>Manage your profile</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText>Manage your team</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <Event />
            </ListItemIcon>
            <ListItemText>Schedule of events</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <Archive />
            </ListItemIcon>
            <ListItemText>Archive</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <TrendingUp />
            </ListItemIcon>
            <ListItemText>Create a success wall</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <ChatBubble />
            </ListItemIcon>
            <ListItemText>Talk to your analyst</ListItemText>
          </MenuItem>
          <MenuItem disabled component={Link} to="/">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText>Manage your dashboard</ListItemText>
          </MenuItem>
        </MenuList>
      </Grid>
    </Grid>
  );
}
