import React from "react";
import {Link} from 'react-router-dom';

class CompletionPageActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false
    }
  }

  componentDidMount() {
    const {getData} = this.props;
    const data = getData();
    const emitter = data.getEmitter();

    emitter.on("NotSyncingSubmission", () => {
      this.setState({
        ready: true
      })
    })
  }

  render() {
    let nextButtonUrl = this.props.attributes.to;
    const {ready} = this.state;

    if(!ready) {
      return <span>Saving progress please wait...</span>
    }
    return (
        <div className="completion-page-actions">
            <Link className={nextButtonUrl ? 'beckon-white-button' : 'beckon-red-button'} to="/apply-for-funding">
              Back to collaboration hub
            </Link>
            {
              nextButtonUrl && (
              <Link className="beckon-red-button" to={nextButtonUrl}>Next activity</Link>
              )
            }
        </div>
    );
  }
}

export default CompletionPageActions;