import React, { useEffect, useMemo, useState } from 'react';
import {compose, connect} from '@formatic/sdk';
import PropTypes from "prop-types";
import get from 'lodash/get';
import { isEmpty, noop } from 'lodash';

const FlowComponent = props => {
    const {flow, pageFlow, getData} = props;
    const data = getData();
    const emitter = data.getEmitter();
    const mainFlow = get(pageFlow,'main',[]);

    const currentFlow = useMemo(()=> {
        const flowNames = Object.keys(pageFlow).reduce((acc,c)=> {
            acc[c] = pageFlow[c][0];
            return acc;
        },{})

        let i = 0;
        // parse flow data
        Object.keys(flowNames).forEach(flowName=>{
            const nextFlowStart = Object.values(flowNames)[i+1];
            const endIndex = (mainFlow.indexOf(nextFlowStart)) - 1;
            const startPageId = flowNames[flowName];
            const endPageId = endIndex > 0 ? mainFlow[endIndex] : null;
            flowNames[flowName] =  {
                startPageId,
                endPageId,
                startIndex: mainFlow.indexOf(startPageId),
                endIndex: mainFlow.indexOf(endPageId)
            }
            i++;
        })
        return get(flowNames,flow,null);
    }, [])

    const pageNavigationHandler = ({id}) => {
        if (id === currentFlow.startPageId) {
            // add class to hide previous button
            document.querySelector('.formatic').classList.add('disable-previous');
            return;
        } else if (id === currentFlow.endPageId) {
            // add class to hide next button
            document.querySelector('.formatic').classList.add('disable-next');
            return;
        }

        // otherwise show both, TODO: enhance this block for performance
        document.querySelector('.formatic').classList.remove('disable-previous');
        document.querySelector('.formatic').classList.remove('disable-next');
    }

    useEffect(()=>{
        if(isEmpty(currentFlow) || flow === null) {
            return;
        }

        const currentPageId = data.getCurrentPageID();
        try {
            // navigate to page belonging to flow only if the page cursor lies on other activies otherwise bring back same page.
            const currentIndex = mainFlow.indexOf(currentPageId);
            if( currentIndex >= currentFlow.startIndex && currentIndex <= currentFlow.endIndex ) {
                throw new Error(0);
            }
            data.navigateToPage(currentFlow.startPageId, 'master', true);
        }catch(e) {}

        // add page render event listner to toggle root buttons visibility
        emitter.on('PageRender', pageNavigationHandler);
        return () => {
            // remove listner on unmount
            emitter.off('PageRender', pageNavigationHandler);
        }
    }, [currentFlow]);

    return null;
}

FlowComponent.defaultProps = {
    data: {},
    pageFlow: {},
    flow: null
};

FlowComponent.propTypes = {
    data: PropTypes.object,
    pageFlow: PropTypes.object,
    flow: PropTypes.string
};

const mapStateToProps = ({
  formatic: {
      data,
      pageFlow
  },
}) => ({
    data,
    pageFlow
});

export default compose(
    connect(mapStateToProps)
)(FlowComponent);
