import React, { useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { CheckCircle, WatchLater } from '@material-ui/icons';
import get from 'lodash/get';
import {store} from '../../store';

const useStyles = makeStyles((theme/*: Theme*/) => createStyles({
  root: {
    width: '260px',
    height: '320px',
    border: '1px solid #bcbcbc',
    borderRadius: '4px',
  },
}));

export default function ActivitySummary(opts /*: { title: string, description: string, buttonTarget: string, percentComplete: number }*/) {
  const {channelName, stage} = opts;
  const classes = useStyles();
  const {state} = useContext(store);
  const {stageProgress} = state;
  const progressValue = Math.round(get(stageProgress, `${stage}.progress.${channelName}`, 0), 2);

  return (
    <>
      <Grid container spacing={2} direction="column" className={classes.root}>
        <Box style={{ borderBottom: '1px solid #bcbcbc' }}>
          <Grid item container spacing={2}>
            <Grid item sm>
              <Typography variant="h5" style={{ margin: '15px 9px' }}>{opts.title}</Typography>
            </Grid>
            <Grid item>
              <img src={opts.img} style={{ marginTop: '8px', marginRight: '4px' }}  alt=''/>
            </Grid>
          </Grid>
        </Box>

        <Grid item container spacing={2}>
          <Grid item container sm>
            <Grid item><WatchLater style={{ color: '#808080' }} /></Grid>
            <Grid item><Typography style={{ marginLeft: '0.3em' }}>{opts.timeRequiredToComplete}</Typography></Grid>
          </Grid>
          <Grid item><Typography>
            {
              progressValue < 100 && (
                <span>
                  {progressValue}% Complete
                </span>
              )
            }
            </Typography></Grid>
        </Grid>

        <Grid item>
          <Typography>{opts.description}</Typography>
        </Grid>
      </Grid>

      <Box style={{ position: 'absolute' }}>
        <Box style={{ position: 'relative', top: -50, left: -7 }}>
          {progressValue >= 100
            ? <Grid item container sm style={{ margin: '16px 8px' }}>
              <Grid item><CheckCircle style={{ color: '#968839' }} /></Grid>
              <Grid item><Typography style={{ marginLeft: '0.3em' }}>Complete</Typography></Grid>
            </Grid>
            : <Button component={Link} to={opts.buttonTarget} className="activity-summary-button">{!progressValue ? 'Start now' : 'Continue'}</Button>
          }
        </Box>
      </Box>
    </>
  );
}