export default ([
  {
      key: 'gettingToKnowYou',
      title: 'Getting to know you',
      duration: '5 steps, about 60 mins',
      image: 'img/growing-with-beckon/journey-1.png',
      paragraphs: [
        'Answering quick and basic questions about your business and vision',
        '...so we can understand your potential place in the Beckon family'
      ],
      stageCompletedMessageParagraphs: [
        'You have completed all of the activities in ‘Getting to know you’.',
        'We will review your responses and contact you within a week.'
      ],
      activities: [
        {
          timeRequiredToComplete:"15 mins",
          img:"img/apply-for-funding/you-and-your-team.png",
          imgAlt: '',
          title:"You and your team",
          buttonTarget:"/you-and-your-team",
          description: 'Let us know who is in your team, their strengths and where you need some more support.',
          channelName: 'youAndYourTeam'
        }, {
          timeRequiredToComplete:"10 mins",
          img:"img/apply-for-funding/your-business.png",
          imgAlt: '',
          title:"Your business",
          buttonTarget:"/your-business",
          description: "Share some basic information about your business.",
          channelName: 'yourBusiness'
        }, {
          timeRequiredToComplete: "20 mins" ,
          img: "img/apply-for-funding/your-dream-and-impact.png" ,
          imgAlt: '',
          title: "Your dream and impact" ,
          buttonTarget: "/your-dream-and-impact",
          description:  "Share your dream and tell us what impact you want to have on the world.",
          channelName: 'yourDreamAndImpact'
        }, {
          timeRequiredToComplete: "5 mins" ,
          img: "img/apply-for-funding/how-we-can-help.png",
          imgAlt: '',
          title: "How we can help",
          buttonTarget: "/how-we-can-help",
          description:  "Let us know how we can support you to grow and develop your business.",
          channelName: 'howWeCanHelp'
        }, {
          channelName: 'yourSuccess',
          timeRequiredToComplete: "10 mins",
          img: "img/apply-for-funding/your-success.png",
          imgAlt: '',
          title: "Your success",
          buttonTarget: "/your-success",
          description:  "Don’t be shy! Share your successes and the things that make you proud.",
        }
      ]
    },
    {
      key: 'findingOpportunitiesForUsBoth',
      title: 'Finding opportunities for us both',
      duration: '1 step, about 45 minutes',
      image: 'img/growing-with-beckon/journey-2.png',
      paragraphs: [
        'Creating a rounded view of your business, its opportunities and its risks',
        '...so we can understand how best to support you with funding and expertise.'
      ],
      stageCompletedMessageParagraphs: [
        'You have completed all of the activities in ‘Finding opportunity for us both’.',
        'We will review your responses and contact you within a week.'
      ],
      activities: [
        {
          timeRequiredToComplete:"45 mins",
          img:"img/apply-for-funding/you-and-your-team.png",
          imgAlt: '',
          title:"Lean investment canvas",
          buttonTarget:"/lean-investment-canvas",
          description: 'Let’s dig a bit deeper to understand your customers, competitors, costs and more.',
          channelName: 'leanInvestmentCanvas'
        }
      ]      
    },
    {
      key: 'divingDeeper',
      title: 'Diving deeper',
      duration: '',
      image: 'img/growing-with-beckon/journey-3.png',
      paragraphs: [
        'Diving deeper into your business, industry and potential',
        '...so we can fairly value your business and its prospectus for growth.'
      ]
    },
    {
      key: 'buildingAPartnership',
      title: 'Building a partnership',
      duration: '',
      image: 'img/growing-with-beckon/journey-4.png',
      paragraphs: [
        'Finalising the documentation that formalises our partnership',
        '...so we can invest in our future together.'
      ]
    },
    {
      key: 'workingTogether',
      title: 'Working Together',
      duration: '',
      image: 'img/growing-with-beckon/journey-4.png',
      paragraphs: [
        'Establishing a working partnership',
        '...so your business achieves its full potential - both financial and positive impact.'
      ]
    }
]);