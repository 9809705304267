import React, {useMemo, useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';

import ActivitySummary from '../../routes/ApplyForFunding/ActivitySummary.js';

const getProgress = (channel, progress) => get(progress,channel, 0);

const GridItem = ({
    activities,
    stageCompletedMessageParagraphs,
    stage,
    isStageComplete
}) => {
    

    useEffect(() => {

    }, [])

    if(isStageComplete) {
        return (
            <div className='complete'>
                <div className='complete-tick'><img src={`https://beehive.beckon.capital/img/complete-tick.png`} alt='complete' /></div>
                <div className='completion-text'>
                    {
                        Array.isArray(stageCompletedMessageParagraphs) && stageCompletedMessageParagraphs.map((messageParagraph, i) => (
                            <p key={i}>{messageParagraph}</p>
                        ))
                    }
                </div>
            </div>
        )
    }

    return <div>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={7} >
            {
                activities.map( activity => {
                    return <Grid item key={activity.channelName}>
                        <ActivitySummary {...activity} stage={stage}/>
                    </Grid>
                })
            }
        </Grid>
    </div>
}

GridItem.propTypes = {
    activities: PropTypes.arrayOf(
        PropTypes.shape({
            channelName: PropTypes.string,
            timeRequiredToComplete: PropTypes.string,
            img: PropTypes.string,
            imgAlt: PropTypes.string,
            title: PropTypes.string,
            buttonTarget: PropTypes.string,
            description: PropTypes.string,
            channelName: PropTypes.string,
        })
    ),
    stageCompletedMessageParagraphs: PropTypes.arrayOf(PropTypes.string),
    progress: PropTypes.object
}

GridItem.defaultProps = {
    activities: PropTypes.shape({
        channelName: '',
        timeRequiredToComplete: '',
        img: '',
        imgAlt: '',
        title: '',
        buttonTarget: '',
        description: '',
        channelName: '',
    }),
    stageCompletedMessageParagraphs: [],
    progress: {}
}

export default GridItem;
