import React, { useEffect, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Icon } from '@material-ui/core';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { store } from '../../store';
import GridItem from './GridItem';
import { stages } from '../../config';
import GridIndexSummary from './GridIndexSummary';

const AccordionDetails = withStyles((theme) => ({
  root: {
    marginLeft: '125px',
  },
}))(MuiAccordionDetails);

const isActiveHelper = (activeStage, stageKey) => {
  if (activeStage === stageKey) {
      return true;
  }
  return stages.indexOf(activeStage) > stages.indexOf(stageKey);
}

const Accordion = withStyles({
  root: {
      boxShadow: 'none',
      backgroundColor: 'inherit !important', // remove "gray out" for "disabled" accordions.
  },
})(MuiAccordion);


const GridIndex = (props) => {
  const {
    stageName,
    title,
    duration,
    image,
    paragraphs,
    activities,
    index,
    stageCompletedMessageParagraphs,
  } = props;
    const {state} = useContext(store);
    const {stageProgress, activeStage} = state;

    const [isActive, setIsActive] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
      const stageDetail = get(stageProgress, stageName, {});
      setIsComplete(get(stageDetail, 'isComplete', false));
      setIsActive(isActiveHelper(activeStage, stageName));
    }, [state])

    useEffect(()=> {
      setIsExpanded(!isComplete && isActive);
    }, [isActive, isComplete])

    const handleChange = () => setIsExpanded(!isExpanded);
    
    const hasValidActivities = useMemo(() => {
      return Array.isArray(activities)
    }, [activities])
    
    return (
        <Accordion expanded={isExpanded} onChange={handleChange} disabled={!isActive}>
          <GridIndexSummary {...props}/>
          <AccordionDetails>
            {
              hasValidActivities && <GridItem stage={stageName} activities={activities} isStageComplete={isComplete} stageCompletedMessageParagraphs={stageCompletedMessageParagraphs}/>
            }
          </AccordionDetails>
        </Accordion>
    )
}

GridIndex.propTypes = {
  stageName: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.string,
  image: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  activities: PropTypes.arrayOf(PropTypes.shape({
    timeRequiredToComplete:PropTypes.string,
    img:PropTypes.string,
    imgAlt:PropTypes.string,
    title:PropTypes.string,
    buttonTarget:PropTypes.string,
    description:PropTypes.string,
    channelName:PropTypes.string
  })),
  stageCompletedMessageParagraphs: PropTypes.arrayOf(PropTypes.string),
}

GridIndex.defaultProps = {
  stageName: '',
  title: '',
  duration: '',
  image: '',
  paragraphs: '',
  activities: [],
  stageCompletedMessageParagraphs: []
}

export default GridIndex;