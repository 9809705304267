import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: '10em',
    marginBottom: '1em',
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <a href = "tel:1300733421">1300 733 421</a> | <a href = "mailto: hello@beckon.capital">hello@beckon.capital</a> | <a href = "https://beehive.beckon.capital/assets/terms_and_conditions.pdf">terms and conditions</a><br />
      &copy; Beckon Capital
    </div>
  );
}
