/*eslint-disable*/
import React, { Component } from "react";
import { Grid } from '@material-ui/core';

/* LAYOUT COMPONENTS */
import AppHeader from './AppHeader.tsx';
import Footer from './Footer.tsx';
import NavMenu from './NavMenu.js';

/* STYLING */
import "./styles/index.scss";

import headerBanner from "./img/header2.jpg";

class BeckonCapitalLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid container className=''>
        <Grid item xs={12} className="layoutHeader">
          <AppHeader />
        </Grid>
        <Grid item xs={12} sm={2} className="layoutMenu">
          <NavMenu />
        </Grid>
        <Grid item xs={12} sm={9} className="layoutContent">
          <h1>{this.props.title}</h1>
          {this.props.children}
        </Grid>
        <Grid item xs={12} sm={12} className="layoutFooter">
          <Footer />
        </Grid>
      </Grid>
    );

   /* return (
      <div className="layout-click-finance">
        <header>
          <div className="container">
            <div className="container-logo-transformd">
              <a href="/" className="logo" >
                <img src="https://beckon.capital/static/logo-50696d2ed2ec3c93d807e9e6a9cd0471.svg"/>                
              </a>
            </div>
            <h2>{this.props.title}</h2>
            </div>
        </header>
        {/* <div
          className="banner"
          style={{ backgroundImage: `url(${headerBanner})` }}
        /> *//*}
        <div className="container">
          <div className="content">{this.props.children}</div>
        </div>
      </div>
      
    );*/
  }
}

export default BeckonCapitalLayout;
