import React from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'

import Loading from './Loading';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#222222'
        }
    }
})

const LoadingContainer = () => (
    <MuiThemeProvider theme={theme}>
        <Loading/>
    </MuiThemeProvider>
)

export default LoadingContainer;