export { default as dashboardData } from './dashboardData';

const conf = {
  "production": {
    AUTH0_DOMAIN: 'beckon.au.auth0.com',
    AUTH0_CUSTOM_DOMAIN: 'auth.beehive.beckon.capital',
    AUTH0_CLIENT_ID: 'JgQOKf5el7doqrsDU0DE1S7GEKJyHclG',
    REDIRECT_URL: 'https://beehive.beckon.capital/auth0callback',
    LOGOUT_URL: 'https://beehive.beckon.capital/',
    TRANSFORMD_FORM_ID: 763,
    TRANSFORMD_PUBLIC_API_KEY: '2Fdae13cFCCBEe2921400EF0cA5a1D233dde32eE6CaD600882511CD8716862b0',
    TRANSFORMD_API_URL: 'https://api.transformd.com',
    TRANSFORMD_API_URL_GRAPHQL: 'https://api.transformd.com/graphql',
    TRANSFORMD_SUBSCRIPTION_URL: 'wss://api.transformd.com/subscriptions',
    TRANSFORMD_ENVIRONMENT: 'Live',
    MD5_SECRET_KEY: 'N@#$<SDP#$MSD>CV?!@E!CS<!!'
  },
  "staging": {
    // staging uses prod auth and prod code
    // :TODO: change Transformd environment to Staging
    AUTH0_DOMAIN: 'beckon.au.auth0.com',
    AUTH0_CUSTOM_DOMAIN: 'beckon.au.auth0.com',
    AUTH0_CLIENT_ID: 'JgQOKf5el7doqrsDU0DE1S7GEKJyHclG',
    REDIRECT_URL: 'http://tfd-bc-2fe32ab.s3-website-ap-southeast-2.amazonaws.com/auth0callback',
    LOGOUT_URL: 'http://tfd-bc-2fe32ab.s3-website-ap-southeast-2.amazonaws.com/',
    TRANSFORMD_FORM_ID: 332,
    TRANSFORMD_PUBLIC_API_KEY: '0191f7383023c2F26b502D1e1808313b802aDA4952789e5bA5d0472c9783313B',
    TRANSFORMD_API_URL: 'https://api-staging.transformd.com',
    TRANSFORMD_API_URL_GRAPHQL: 'https://api-staging.transformd.com/graphql',
    TRANSFORMD_SUBSCRIPTION_URL: 'wss://api-staging.transformd.com/subscriptions',
    TRANSFORMD_ENVIRONMENT: 'Staging',
    MD5_SECRET_KEY: 'N@#$<SDP#$MSD>CV?!@E!CS<!!'
  },
  "development": {
    AUTH0_DOMAIN: 'beckon-dev.au.auth0.com',
    AUTH0_CUSTOM_DOMAIN: 'beckon-dev.au.auth0.com',
    AUTH0_CLIENT_ID: 'R2TYLSOcGlyW7NdWMd2T3m1HEvTdQ3Nf',
    REDIRECT_URL: 'http://localhost:3001/auth0callback',
    LOGOUT_URL: 'http://localhost:3001/',
    TRANSFORMD_FORM_ID: 332,
    TRANSFORMD_PUBLIC_API_KEY: '0191f7383023c2F26b502D1e1808313b802aDA4952789e5bA5d0472c9783313B',
    TRANSFORMD_API_URL: 'https://api-staging.transformd.com',
    TRANSFORMD_API_URL_GRAPHQL: 'https://api-staging.transformd.com/graphql',
    TRANSFORMD_SUBSCRIPTION_URL: 'wss://api-staging.transformd.com/subscriptions',
    TRANSFORMD_ENVIRONMENT: 'Staging',
    MD5_SECRET_KEY: 'N@#$<SDP#$MSD>CV?!@E!CS<!!'
  }
};

export const stages = [
  'gettingToKnowYou',
  'findingOpportunitiesForUsBoth',
  'divingDeeper',
  'buildingAPartnership',
  'workingTogether',
];

export const fieldsConfig = {
  progress: '60249602f70d4800f3507e64',
  status: '6025df2d6c4b6a00102058f7',
  pageProgress: '6004eef4400af4007375af0a',
}

export const config = conf[process.env.NODE_ENV];
