import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

/* AUTH */
import auth from './Auth0FE';
import GuardedRoute from './components/misc/GuardedRoute';
import Auth0Callback from './components/misc/Auth0Callback';

/* Fullstory */
import * as FullStory from '@fullstory/browser';

/* ROUTES */
import ApplyForFunding from "./routes/ApplyForFunding";
import YourBusiness from "./routes/YourBusiness";
import YouAndYourTeam from "./routes/YouAndYourTeam";
import HowWeCanHelp from "./routes/HowWeCanHelp";
import YourDreamAndImpact from "./routes/YourDreamAndImpact";
import LeanInvestmentCanvas from "./routes/LeanInvestmentCanvas";
import YourSuccess from "./routes/YourSuccess";
import APostRoute from "./routes/apost/APostRoute";
import EmailVerification from "./routes/EmailVerification"
import { StateProvider } from "./store";

class App extends Component {
  silentlyAuthorised = false;

  async componentDidMount() {
    try {
      await auth.silentAuth();
    } catch (err) {
      if (err.error !== 'login_required' && err.error !== 'consent_required') {
        // eslint-disable-next-line no-console
        console.error('ERROR trying to authenticate', err);
      }
    }

    /* Init Fullstory and set some vars */
    FullStory.init({ orgId: '10KZWA' });
    try {
      let fsUserEmail = auth.getUserEmail();
      let fsUserId = auth.getAuth0UserId();
      
      FullStory.identify(fsUserId, {
        displayName: fsUserEmail,
        email: fsUserEmail
      });
    } catch (err) {
      console.error('auth.email is empty', err);
      FullStory.identify('68686868', {
        displayName: 'unknown',
        email: 'unknown'
      });
    }

    this.silentlyAuthorised = true;
    this.forceUpdate();
  }


  // @Todo - move these routes into a single component. 

  render() {
    return !this.silentlyAuthorised ? '' : (
      <StateProvider>
        <BrowserRouter>
          <Switch>
            <GuardedRoute exact path="/" component={ApplyForFunding} />
            <GuardedRoute exact path="/apply-for-funding" component={ApplyForFunding} />
            <GuardedRoute exact path="/your-business" component={YourBusiness} />
            <GuardedRoute exact path="/your-success" component={YourSuccess} />
            <GuardedRoute exact path="/your-dream-and-impact" component={YourDreamAndImpact} />
            <GuardedRoute exact path="/how-we-can-help" component={HowWeCanHelp} />
            <GuardedRoute exact path="/you-and-your-team" component={YouAndYourTeam} />
            <GuardedRoute exact path="/lean-investment-canvas" component={LeanInvestmentCanvas} />
            <GuardedRoute path="/channel/:name?" component={APostRoute} />
            <GuardedRoute exact path='/auth0callback' component={Auth0Callback} />
            <Route exact path='/verify-email' component={EmailVerification} />
          </Switch>
        </BrowserRouter>
      </StateProvider>
    );
  }
}

export default App;
