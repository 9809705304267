import React, { Component } from "react";
import { Overrides, Data } from "@formatic/sdk";
import get from 'lodash/get';

import BeckonCapitalLayout from "../../layouts/BeckonCapital";
import Formatic from "../../components/Formatic";
import { ParamsFromURL } from "../../javascript/common";

import "../../layouts/BeckonCapital/styles/components/_reviewTable.scss";

let params;
const data = new Data();
const formProps = {
  apiServerUrl: "https://api-odin.dev.transformd.com",
  serverUrl: "https://sdk-api-odin.dev.transformd.com/graphql",
  subscriptionServerUrl: "wss://sdk-api-odin.dev.transformd.com/subscriptions",
};

const officeFieldsForChannel = {
  'youAndYourTeamCurrentPage': '5fc062f840c33f72697df1a6',
  'yourBusinessCurrentPage': '5fc066b3c40701165712cd06'
}

class APostRoute extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.channelName = get(props,'match.params.name','dashboard');
    params = ParamsFromURL(props.location.search);
  }

  setCurrentPage = async ({id}) => {
    try {
      const fieldId = officeFieldsForChannel[`${this.channelName}CurrentPage`];
      await data.setFieldValue( fieldId, id);
    }catch(e){
      //console.warn("set current page failed", e);
    }
  }

  componentDidMount () {
    const emitter = data.getEmitter();

    emitter.on("PageRender", this.setCurrentPage)
  }

  componentWillUnmount = () => {
    const emitter = data.getEmitter();

    emitter.off("PageRender", this.setCurrentPage)
  }

  render() {
    return (
      <BeckonCapitalLayout title="Apply for funding">
        <div className="page__example-fsf">
          <Formatic
            data={data}
            {...formProps}
            formId={23}
            apiKey="fbfec0C6Cf0e897130964D6eE71567d7297002ca68900A066Ed4BCCb50841f62"
            environment="Development"
            channel={this.channelName || "youAndYourTeam"}
            config="default"
            theme="transformd"
            submissionId={params.sessionId}
            runReduxDevTools
          >
          </Formatic>
        </div>
      </BeckonCapitalLayout>
    );
  }
}

export default APostRoute;
