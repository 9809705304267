import React from "react";
import BeckonCapitalLayout from "../../layouts/BeckonCapital";
import CustomChannelTrigger from "../../components/CustomChannelTrigger";
import FormaticSDK from "../../components/Formatic";
import { config } from '../../config';
import { Overrides, Data } from "@formatic/sdk";
import auth from '../../Auth0FE';

const data = new Data();
const formProps = {
  apiServerUrl: config.TRANSFORMD_API_URL,
  serverUrl: config.TRANSFORMD_API_URL_GRAPHQL,
  subscriptionServerUrl: config.TRANSFORMD_SUBSCRIPTION_URL,
};

const yourSuccess = () => (
  <BeckonCapitalLayout title="Your success to date">
    <div className="page__success-form">
          <FormaticSDK
            {...formProps}
            formId={config.TRANSFORMD_FORM_ID}
            apiKey={config.TRANSFORMD_PUBLIC_API_KEY}
            environment={config.TRANSFORMD_ENVIRONMENT}
            // please continue using flow and flow name, as we're pulling page information from dashboard flow
            flow={"yourSuccess"}
            channel={"yourSuccess"}
            config="default"
            theme="transformd"
            submissionId={auth.getTransformdSubmissionId()}
            initialValues={{"5ff3b61de00ec80104366c58":"yourSuccess", "userId":auth.getAuth0UserId()}} 

            runReduxDevTools
          >
          </FormaticSDK>
    </div>
  </BeckonCapitalLayout>
);

export default yourSuccess;
