import React from "react";
import BeckonCapitalLayout from "../../layouts/BeckonCapital";
import { AlertTitle, Alert } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import auth from "../../Auth0FE";

const emailVerification = () => (
  <BeckonCapitalLayout title="Please verify your email">
    <Grid className="verify-email">
      <Alert severity="info">
        <AlertTitle>We've sent an email to {auth.getUserEmail()}</AlertTitle>
        You'll need to verify your email before you can start your application.
      </Alert>

    </Grid>
  </BeckonCapitalLayout>
);

export default emailVerification;
