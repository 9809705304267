import React from 'react';
import { createStyles, makeStyles, Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import auth from '../../Auth0FE';

//export default withStyles(theme => (styles))(AppHeaderPage);

export default function AppHeaderPage() {
  //const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div /* className={classes.root} */>
      <AppBar position="static" /* className={classes.appBar} */>
        <Toolbar>
          <Typography variant="h6" /* className={classes.title} */>
            <img src='img/beckon-capital-logo.svg' width='180' height='41' />
          </Typography>
          {auth.isAuthenticated()  ? (
            <div className="profileIcon">
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                style={{ backgroundColor: 'transparent' }}
              >
                <AccountCircle color="primary" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                {/*<MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem component={Link} to="/my-account" onClick={handleClose}>My account</MenuItem>*/}
                <MenuItem onClick={() => auth.logout()}>Log Out</MenuItem>
              </Menu>
            </div>
          ) : (
            <Button onClick={() => auth.login()}>Log In</Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

