import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../Auth0FE';

function GuardedRoute(props) {
  const { component: Component, path } = props;
  return (
    <Route exact path={path} render={(props1) => {
      if (!auth.isAuthenticated()) return auth.login(path);
      if (!auth.getUserEmailIsVerified()) return <Redirect to='/verify-email' />
      return <Component {...props1} />;
    }} />
  );
}

export default GuardedRoute;
