import React from "react";
import BeckonCapitalLayout from "../../layouts/BeckonCapital";
import FormaticSDK from "../../components/Formatic";
import auth from '../../Auth0FE';
import { config } from '../../config';

const formProps = {
  apiServerUrl: config.TRANSFORMD_API_URL,
  serverUrl: config.TRANSFORMD_API_URL_GRAPHQL,
  subscriptionServerUrl: config.TRANSFORMD_SUBSCRIPTION_URL,
};

const yourBusiness = () => (
  <BeckonCapitalLayout title="Your business">
    <div className="page__business-form">
          <FormaticSDK
            {...formProps}
            formId={config.TRANSFORMD_FORM_ID}
            apiKey={config.TRANSFORMD_PUBLIC_API_KEY}
            environment={config.TRANSFORMD_ENVIRONMENT}
            // please continue using flow and flow name, as we're pulling page information from dashboard flow
            flow={"yourBusiness"}
            channel={"yourBusiness"}
            config="default"
            theme="transformd"
            submissionId={auth.getTransformdSubmissionId()}
            initialValues={{"5ff3b61de00ec80104366c58":"yourBusiness", "userId":auth.getAuth0UserId()}}
            runReduxDevTools
          >
          </FormaticSDK>
    </div>
  </BeckonCapitalLayout>
);

export default yourBusiness;
