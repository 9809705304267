import React, {useMemo, useEffect, useContext} from 'react';
import {connect} from "@formatic/sdk";
import get from 'lodash/get';

import {fieldsConfig} from '../../config';
import {store} from '../../store';

const mapStateToProps = ({formatic: {
    pageFlow,
    tokens,
    data
}}) => ({
    pageFlow,
    tokens,
    data
})

const ProgressCalculation = (props) => {
    const {dispatch} = useContext(store);
    const data = props.getData();
    const {pageFlow, tokens} = props;
    const pageProgress = useMemo(() => {
        return get(tokens, `fields.pageProgress`, {})
    }, [tokens])
 
    const calculateFlowProgress = () => {
        const flows = Object.keys(pageFlow);

        const progress = {};
        flows.forEach(flowName => {
            const channelCurrentPage = get(tokens, `fields.pageProgess.${flowName}`, null);
            const pagesInFlow = get(pageFlow, flowName, []);
            const currentPosition = pagesInFlow.indexOf(channelCurrentPage);
            const percentage = ( (currentPosition+1)/pagesInFlow.length) * 100;
            if(percentage > 100 || percentage < 0 || isNaN(percentage)) {
                progress[flowName] = 'Not Started';
            }
            progress[flowName] = percentage;
        })
        
        const activeStage = data.getFieldValue(fieldsConfig.status);
        dispatch({
            type: 'set-stage-progress',
            payload: {
                progress,
                activeStage,
                cb: (value) => {
                    data.setFieldValue(
                        fieldsConfig.progress,
                        value
                    )
                }
            }
        });
    }

    useEffect(() => {
        calculateFlowProgress();
    }, [])

    return null;
}

export default connect(mapStateToProps)(ProgressCalculation);