import React, {useContext} from 'react';
import FormaticSDK, { Overrides, Data } from "@formatic/sdk";

import {store} from '../../store';
import { config } from '../../config';
import auth from '../../Auth0FE';
import ProgressCalculation from './ProgressCalculation';
import { Button } from '@material-ui/core';
import Loading from '../../components/Loading';

const data = new Data();
const formProps = {
  apiServerUrl: config.TRANSFORMD_API_URL,
  serverUrl: config.TRANSFORMD_API_URL_GRAPHQL,
  subscriptionServerUrl: config.TRANSFORMD_SUBSCRIPTION_URL,
};

const getData = () => data;

const TransformdComponent = ({children}) => {
    const {state} = useContext(store);
    const {ready} = state;

    return (
        <div className="formatic-progress-wrapper">
            {
                !ready && <Loading/>
            }
            <FormaticSDK
                {...formProps}
                formId={config.TRANSFORMD_FORM_ID}
                apiKey={config.TRANSFORMD_PUBLIC_API_KEY}
                environment={config.TRANSFORMD_ENVIRONMENT}
                channel={"dashboard"}
                config="default"
                theme="beckon-capital"
                submissionId={auth.getTransformdSubmissionId()}
                data={data}
            >
                <Overrides.OverrideFieldContainer getData={getData} component={ProgressCalculation} type="withFormaticProgress" />
            </FormaticSDK>
            { ready && children }
        </div>
    )
}


export default TransformdComponent;