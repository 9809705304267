import React, { useEffect, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Icon } from '@material-ui/core';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { store } from '../../store';
import GridItem from './GridItem';
import { stages } from '../../config';

const useStyles = makeStyles((theme) => createStyles({
    journeyImg: {
      marginTop: '32px',
      width: '100px',
      height: '90px',
      marginRight: '2em',
    },
  }));

const successColorStyle = {
    color: "#968839",
    fontSize: 25
}


const AccordionSummary = withStyles({
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(MuiAccordionSummary);

const isActiveHelper = (activeStage, stageKey) => {
    if (activeStage === stageKey) {
        return true;
    }
    return stages.indexOf(activeStage) > stages.indexOf(stageKey);
}

const GridIndexSummary = ({
    stageName,
    title,
    duration,
    image,
    paragraphs,
    activities,
    index,
    stageCompletedMessageParagraphs,
}) => {
    const classes = useStyles();
    const stageState = useContext(store);
    const { state, dispatch } = stageState;
    const activeStage = get(state, 'activeStage', '');

    const isComplete = useMemo(()=> get(state, `stageProgress.${stageName}.isComplete`, false), [state]);
    const isActive = isActiveHelper(activeStage, stageName);
  
    return(
        <AccordionSummary expandIcon={ <ExpandMoreIcon />}>
        <img src={`https://beehive.beckon.capital/${image}`} className={classes.journeyImg} alt={image}/>
        <Box>
            <Grid container>
              <Grid item xs >
                  <h2>
                      {
                          isComplete && <Icon fontSize={"large"} style={successColorStyle}>check_circle</Icon>
                      }
                      {" "}
                      {title}
                      {" "}
                      {
                          !isActive && <Icon>lock</Icon>
                      }
                  </h2>
              </Grid>
            <Grid item><p style={{ position: 'relative', top: 15 }}>{duration}</p></Grid>
            </Grid>
            {
                Array.isArray(paragraphs) && paragraphs.map( (paragraph,i) => (
                    <Typography key={i} paragraph>{paragraph}</Typography>
                ))
            }
        </Box>
        </AccordionSummary>
    )
}

export default GridIndexSummary;