import React from "react";
import PropTypes from "prop-types";
import FormaticSDK, {Data, Overrides} from "@formatic/sdk";
import AbnLookupComponent from "@formatic/abn-lookup"
import isEmpty from 'lodash/isEmpty';

import {config, fieldsConfig} from '../../config';
import completeActions from "../CompletionPageActions";
import CustomChannelTrigger from '../CustomChannelTrigger';

import FlowComponent from './FlowComponent';
import LoadingContainer from "../Loading";

const omitChannels = [
  'dashboard',
  'master'
]

const data = new Data();
class Formatic extends React.Component {
  constructor(props) {
    super(props);
  }

  trackProgressOnPageRender = (page) => {
    const {id} = page;
    const {channel} = this.props;

    if(omitChannels.indexOf(channel) > -1) {
      return;
    }

    let progressData = data.getFieldValue(fieldsConfig.pageProgress);
    if(isEmpty(progressData)) {
      progressData = {};
    }
    
    data.setFieldValue(
      fieldsConfig.pageProgress,
      {
        ...progressData,
        [channel]: id
      }
    )
  }

  componentDidMount = () => {
    const emitter = data.getEmitter();
    emitter.on("BeforePageRender", this.trackProgressOnPageRender)
  }

  componentWillUnmount = () => {
    const emitter = data.getEmitter();
    emitter.off("BeforePageRender", this.trackProgressOnPageRender)
  }

  getDataHelper = () => {
    return data;
  }

  render () {    
    return (
      <FormaticSDK {...this.props} data={data}>
        <Overrides.OverrideFieldContainer getData={()=>this.getDataHelper} component={CustomChannelTrigger} type="customChannelButton" />        
        <Overrides.OverrideFieldContainer getData={this.getDataHelper} type={"completeActions"} component={completeActions}/>
        <Overrides.OverrideFieldContainer component={AbnLookupComponent} apiServerUrl={config.TRANSFORMD_API_URL} type="abnLookup" />
      </FormaticSDK>
    )
  }
}

Formatic.defaultProps = {
  children: [],
  channel: 'master',
};

Formatic.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  channel: PropTypes.string,
};

export default Formatic;
