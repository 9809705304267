import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import auth from '../../Auth0FE';

class Callback extends Component {
  async componentDidMount() {
    await auth.handleAuthentication();
    this.props.history.replace(auth.getRedirectUri());
  }

  render() {
    return ''/* 'Loading...'*/;
  }
}

export default withRouter(Callback);
