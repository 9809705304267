import React, { useEffect, useContext } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BeckonCapitalLayout from '../../layouts/BeckonCapital/BeckonCapitalLayout'
import { dashboardData } from '../../config/index.js';
import GridIndex from '../../components/DashboardGrid';
import { Link } from 'react-router-dom';
import TransformdComponent from '../../services/TransformdComponent';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
  }
}));

const ApplyForFunding = () => {
  const classes = useStyles();

  return (
    <BeckonCapitalLayout title="Apply for funding">
      <TransformdComponent>
        <div className={classes.root}>
          <Typography paragraph>The investment journey takes us from strangers to partners in clear steps that help Beckon achieve insight about your business, vision and potential, while respecting your time and commitment.</Typography>
          <Typography paragraph>Our aim is that, whether or not we invest in you, the investment journey will have helped you think differently about your business, your markets and your growth potential.</Typography>
          {
            dashboardData.map((stage, index)=> (
              <GridIndex {...stage} index={index} key={index} stageName={stage.key}/>
            ))
          }
        </div>
      </TransformdComponent>
    </BeckonCapitalLayout>
  );
}

export default ApplyForFunding;
