import React from 'react'
//import Proptype from 'prop-types';
import {connect} from "@formatic/sdk";
import {Link} from 'react-router-dom';
import { get } from 'lodash';

/*
const officeFieldsForChannel = {
    'youAndYourTeamCurrentPage': '5fc062f840c33f72697df1a6',
    'yourBusinessCurrentPage': '5fc066b3c40701165712cd06'
}
*/
  
class CustomChannelTrigger extends React.Component {
    state = {
        progress: null
    }
    componentDidMount() {
        const {pageFlow, attributes} = this.props;
        const {channel} = attributes;

        const channelCurrentPage = get(this.props, `tokens.fields.${channel}CurrentPage`, null);
        const channelFlow = get(pageFlow,channel, []);
    
        try {
            const currentPosition = channelFlow.indexOf(channelCurrentPage);
            const percentage = ( (currentPosition+1)/channelFlow.length) * 100;
            if(percentage > 100 || percentage < 0 || isNaN(percentage)) {
                return;
            }
            this.setState({
                progress: `${percentage}%`
            })
        }catch(e){}
    }

    render() {
        const {attributes} = this.props;
        const {progress} = this.state;
        const {label,time, currentPageOfficeField,description,channel,buttonLabel} = attributes;
        return <div className="custom-component__custom-channel-buttons">
            <div className="heading">
                <h2>{label}</h2>
                <img src={require('../../layouts/BeckonCapital/img/beckon_b.png')}/>
            </div>
            <div className="content">
                <ul>
                    <li><img src={require('../../layouts/BeckonCapital/img/clock.png')}/> {time}</li>
                    <li>{progress !== null && progress} {progress === null && '0%'}</li>
                </ul>
                <p dangerouslySetInnerHTML={{__html:description}}></p>
            </div>
            <Link to={`/channel/${channel}`}>
                <button>
                    { 
                        progress !== null && 
                        "Continue"
                    }
                    {
                        progress === null &&
                        buttonLabel
                    }
                </button>
            </Link>
        </div>
    }
}

CustomChannelTrigger.propTypes = {

}
CustomChannelTrigger.defaultProps = {
}

const mapStateToProps = ({formatic}) => {
    return formatic;
}


export default connect(mapStateToProps)(CustomChannelTrigger);