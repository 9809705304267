import auth0 from 'auth0-js';
import sha256 from 'crypto-js/hmac-md5';
import { config } from './config';

class Auth {
  auth0;
  accessToken;
  redirectUri;
  expiresAt;
  idToken;
  userDetails;

  // changed:
  // domain = custom-domain
  // audience = auth0 domain
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: config.AUTH0_CUSTOM_DOMAIN,
      clientID: config.AUTH0_CLIENT_ID,
      redirectUri: config.REDIRECT_URL,
      audience: `https://${config.AUTH0_DOMAIN}/api/v2/`,
      responseType: 'id_token token', // get both: access token and id token.
      scope: 'openid profile email', // access token provides access to user profile information like email image etc.
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login(path) {
    // eslint-disable-next-line no-restricted-globals
    const redirectUri = path ? path : location.pathname;
    this.auth0.authorize({ state: redirectUri });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getRedirectUri() {
    return this.redirectUri;
  }

  /**
   * Returns the unique Auth0 identifier string 
   */
  getAuth0UserId() {
    return this.userDetails.sub;
  }

  getUserName() {
    return this.userDetails.name;
  }

  getUserEmail() {
    return this.userDetails.email;
  }

  getUserEmailIsVerified() {
    if (typeof this.userDetails === 'undefined')
      return false;

    return this.userDetails.email_verified
  }

  /**
   * This returns a 40character unique submission ID based on the user's Auth0 id
   * The sha256 is 32 chars so we pad it with 8 chars.
   */
  getTransformdSubmissionId() {
    let md5UserId = sha256( this.getAuth0UserId(), config.MD5_SECRET_KEY);
    let eightCharStr = 'jMl2nP8e';
    return md5UserId + eightCharStr;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          reject(err);
        } else if (!authResult || !authResult.idToken) {
          reject(err);
        } else {
          this.setSession(authResult);
          resolve();
        }
      });
    });
  }

  setSession(authResult) {
    this.accessToken = authResult.accessToken;
    this.redirectUri = authResult.state;
    this.idToken = authResult.idToken;
    this.userDetails = authResult.idTokenPayload;
    if (config.DEVELOPMENT) {
      // eslint-disable-next-line no-console
      console.info(`%cFor use at back-end playground (e.g., localhost:4000): %c \n{"Authorization": "Bearer ${this.accessToken}"}`, 'font-weight: bold', '');
    }
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  }

  logout() {
    this.auth0.logout({
      returnTo: config.LOGOUT_URL,
      clientID: config.AUTH0_CLIENT_ID,
    });
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          // Turn it to Error to attach the call stack (best practice), and copy response fields across (specifically, error code).
          const error = Object.assign(new Error(`${err.description}\nResponse: ${JSON.stringify(err, null, 2)}`), err);
          reject(error);
        } else {
          this.setSession(authResult);
          resolve();
        }
      });
    });
  }

  isAuthenticated() {
    return new Date().getTime() < (this.expiresAt ? this.expiresAt : 0);
  }
}

const auth = new Auth();

export default auth;
