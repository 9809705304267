import React, { useReducer } from 'react';
import get from 'lodash/get';
import dashboardData from '../config/dashboardData';


const activitiesPerChannel = dashboardData.map((channelDetail) => {
    return {
        key: channelDetail.key,
        activities: get(channelDetail,'activities')
    }
})

const initialState = {
    data: null,
    activeStage: 'gettingToKnowYou',
    stageProgress: {},
    ready: false,
    progress: {},
}

const store = React.createContext(initialState);
const {Provider} = store;

const getProgress = (channel, progress) => get(progress,channel, 0);

const handleDispatch = (state, {type, payload}) => {
    switch(type) {
        case 'set-data':
            return ({
                ...state,
                data: payload
            })
        case 'set-stage-progress':
            // const {data} = payload;
            const {progress, cb, activeStage: givenActiveStage} = payload;
            // let activeStage = data.getFieldValue(configFieldId.status);
            let currentActiveStage = givenActiveStage
            if(!givenActiveStage) {
                currentActiveStage = state.activeStage;
            }

            const stageDetail = activitiesPerChannel.reduce((acc, {key, activities}) => {
                const stageProgress = {
                    'progress': {},
                    'isComplete': false
                };

                if(activities) {
                    stageProgress.progress = activities.reduce( (acc, {channelName, title}) => {
                        acc[channelName] = getProgress(channelName, progress);
                        return acc;
                    }, {})
                    stageProgress.isComplete = Math.min(...Object.values(stageProgress.progress)) >= 100;
                }

                acc[key] = stageProgress;
                return acc;
            }, {})

            cb(stageDetail);
            return ({
                ...state,
                activeStage: currentActiveStage,
                progress: progress,
                ready:true,
                stageProgress: stageDetail
            })
        case 'set-progress':
            return ({
                ...state,
                progress: payload
            })
        default:
            return initialState;
    }
}

const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer(handleDispatch, initialState)

    return <Provider value={{state, dispatch}}>
        {children}
    </Provider>
}

export {store, StateProvider}